@import "_variables";
@import "utility";
@import "sideBar";
@import "header";
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.border-right-1 {
  border-right: 1px solid #e2e2e2b5;
}
.text-yellow {
  color: #fdac41;
}
body {
  // font-family: "Montserrat", sans-serif;
  font-size: 0.963rem;
  // font-family: "Roboto Condensed", sans-serif;
  font-family: "Rubik", sans-serif;
  color: #5e5873;
}

body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: $scrollbar;
  height: 6px;
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: $primarylightbg;
  border-radius: 10px;
  &:hover {
    background-color: rgba($primary, 0.65);
  }
}

.show {
  opacity: 1;
}

.dropdown-toggle::after {
  display: none;
}

.menu-fixed {
  position: fixed;
}

.content {
  @include respond-to("large") {
    margin-left: $mainMenuW;
    padding: 5rem 2rem 2rem;
  }
  padding: 5rem 1rem 0;
  background: #f6f6f6;
}

.menu-collapsed {
  .content {
    // margin-left: $mainMenuCW;
    @include respond-to("large") {
      margin-left: $mainMenuCW;
    }
  }
}

.cardsummary-body {
  h4 {
    font-size: 1rem;
    color: #5e5873;
  }
  .card-text {
    font-size: 0.8rem;
    color: #6e6b7b;
  }
}

.addModal {
  .arrowdown {
    position: absolute;
    right: 20px;
    top: 5px;
  }
}

.addPickupPoints {
  .card {
    margin-bottom: 2.2rem;
    .card-title {
      font-weight: 600;
    }
  }
}

.CreateOrder {
  .card {
    margin-bottom: 2.2rem;
    .card-title {
      font-weight: 600;
    }
  }
}

.OrderDetail {
  .card-title {
    font-weight: 600;
  }
  .card-subtitle {
    line-height: unset;
  }
}

.AccountSetting,
.dashboard,
.MerchantSetting,
.merchantDetails,
.courierDetails {
  .nav-pills {
    .nav-link {
      font-weight: 500;
      color: #5e5873;
      font-size: 0.963rem;
      padding: 10px 15px;
      transition: unset;

      svg {
        width: 18px;
        height: 18px;
      }

      &.active {
        background-color: $primary;
        box-shadow: 0 4px 18px -4px rgba($primaryrgb, 0.65);
        color: #fff;
      }
    }
  }
}

.dashboard{
  .header-tabs{
    .nav-link {
      font-size: 0.9rem;
      padding: 5px 15px;
    }
  }
}

.channelOrder {
  .nav-tabs {
    overflow-y: hidden;
    overflow-x: auto;
    .nav-link.warning-ctm {
      svg {
        color: $warning;
      }
    }
    .nav-link.danger-ctm {
      svg {
        color: $danger;
      }
    }
    .nav-link.success-ctm {
      svg {
        color: $success;
      }
    }
    .nav-link {
      min-width: 100px;
      svg {
        width: 18px;
        height: 18px;
      }
      &.active {
        border-top: 3px solid;
      }
      &.active.warning-ctm {
        border-color: $warning #dee2e6 #fff;
      }
      &.active.danger-ctm {
        border-color: $danger #dee2e6 #fff;
      }
      &.active.success-ctm {
        border-color: $success #dee2e6 #fff;
      }
    }
  }
  .cust-badge.badge-warning {
    @include badge(#fff, $warning);
    font-weight: 500;
  }
  .cust-badge.badge-danger {
    @include badge(#fff, $danger2);
    font-weight: 500;
  }
  .cust-badge.badge-success {
    @include badge(#fff, $success2);
    font-weight: 500;
  }
  .small-check-ctm {
    width:11px;
    height:11px;
    display: inline-flex;
    justify-content:center;
    align-items:center;
    background: $success2;
    color:#fff;
    border-radius:50%;
    .fa-check{
      height:7px;
    }
  }
  .close-Tag{
    color:$danger;
    position: absolute;
    top:-3px;
    right:-3px;
    svg{
      width:10px;
      height:10px;
    }
  }
}

.swal2-container{
  .confirm-swal-fortag-con{
    width: 375px;  
    .cancel-odr-remarks{
      height:6.75em;
      width: auto;
    }
    &.rts-con{
      .swal2-html-container{
        margin: 1em 1.5em 0.3em;
      }
    }
  }
  .swal_scs_err_popup{
    width: 375px;
    .swal2-icon{
      font-size: 0.7rem;
    }
    .swal2-title{
      font-size: 1.5rem;
    }
  }
}


.another-act-list{
  .list-group-item{
    font-size: 14px;
    .list-item{
      padding: 0.3rem 1.28rem;
      cursor: pointer;

      &:hover{
        color: #1b5a8f;
        background: rgba(27, 90, 143, 0.11);
      }
    }
    .avatar {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-color: $primarylightbg;
      color: $primary;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      .avatar-status-online {
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: rgba(115, 103, 240, 0.12);
        top: 1px;
        right: 15px;
      }
      img {
        box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12),
          0 2px 4px 0 rgba(34, 41, 47, 0.08);
      }
    }
  }
}

.LoginPage,
.RegisterPage,
.ForgetPasswordPage {
  p {
    font-size: 14px;
  }
  .input-group-text {
    svg {
      width: 14px;
      height: 14px;
    }
  }
  .brand-logo {
    background: #fff;
  }
  @include respond-to("large") {
    .brand-logo {
      position: absolute;
      top: 2rem;
      left: 2rem;
      width: auto;
      z-index: 2;
      background: unset;
    }
  }
}
.overview-card {
  .media {
    align-items: center;
  }
}
.overview {
  .media {
    align-items: center;
    border-right: 1px solid #d3d3d3;
    &:last-child {
      border-right: 0;
    }
  }
  .card {
    font-size: 15px;
    .title-card {
      text-align: left;
      margin-bottom: 0;
      font-size: 10px;
    }
    .card-val {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
    .card-icon {
      .badge {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-size: 1rem;
        @include respond-to-max("small") {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.formLoader {
  position: fixed;
  top: 0;
  left: 0;
  background: #92929252;
  width: 100%;
  height: 100%;
  z-index: 9999;
  .spinner-border {
    position: absolute;
    width: 3rem;
    border: 0.1em solid currentColor;
    height: 3rem;
    top: 50%;
    left: 50%;
  }
}

.MerchantSetting {
  .card {
    .courierName {
      max-width: 300px;
      width: 100%;
    }
    .courierSwitch {
      width: 130px;
    }
  }
  .mycourierlist {
    @include respond-to-max("small") {
      .courierToggler {
        order: 1;
      }
      .courierId {
        order: 2;
      }
      .courierSwitch {
        order: 3;
        max-width: calc(100% - 145px);
        width: 100%;
        text-align: right;
        margin-left: auto;
      }
      .courierLogo {
        order: 4;
      }
      .courierName {
        order: 5;
        width: calc(100% - 92px);
      }
    }
  }
}

.merchantPricing {
  .card {
    .courierName {
      max-width: 300px;
      width: 100%;
    }
    .courierSwitch {
      max-width: 130px;
      width: 100%;
    }
  }
  .mycourierlist {
    @include respond-to-max("small") {
      .courierToggler {
        order: 1;
      }
      .courierId {
        order: 2;
      }
      .courierSwitch {
        order: 3;
        max-width: calc(100% - 145px);
        width: 100%;
        text-align: right;
        margin-left: auto;
      }
      .courierLogo {
        order: 4;
      }
      .courierName {
        order: 5;
        width: calc(100% - 92px);
      }
    }
  }
}

.editorClassName {
  min-height: 300px;
  line-height: 0.8;
}

.statistics {
  .avatar {
    .avatar-content {
      width: 48px;
      height: 48px;
      font-size: 22px;
    }
  }
}

.invoiceDetail {
  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

.remittance-detail {
  .list-group {
    font-size: 0.9rem;
    .card-subtitle {
      font-size: 0.9rem;
      color: inherit;
    }
  }
}
.freight-detail {
  .list-group {
    font-size: 0.9rem;
    .card-subtitle {
      font-size: 0.9rem;
      color: inherit;
    }
  }
}

.delivery-type-list {
  h6 {
    font-weight: 600;
  }
}

.channelIntegration {
  .table {
    color: #6e6b7b;
    font-size: 14px;
    td {
      padding: 0.72rem 2rem;
    }
    thead {
      position: relative;
      th {
        border-top: none;
        background-color: #f3f2f7;
        color: #6e6b7b !important;
        border: none;
        padding: 0.72rem 2rem;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.5px;
      }
    }
    .avatar-content {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
      padding: 0.3rem;
      img {
        width: 100%;
      }
    }
  }
}

.modal {
  .form-group {
    .input-group-text svg {
      width: 14px;
      height: 14px;
    }
  }
}

.RemittanceOverview {
  th {
    vertical-align: top !important;
  }
}

.orderMan {
  .nav-tabs {
    .nav-item {
      .nav-link {
        &.active {
          border-top: 3px solid;
        }
        @include respond-to-max("small") {
          .text {
            order: 3;
          }
          .count {
            order: 2;
          }
        }

        .badge {
          border-radius: 20px;
          font-weight: 500;
          line-height: 13px;
          font-size: 12px;
          padding: 0.2rem 0.4rem;
          @include respond-to("small") {
            transform: translateY(-7px);
          }
        }
      }
    }
    .nav-item {
      .nav-link.success-ctm {
        svg {
          color: $success;
        }
        .badge {
          background: $success;
          color: #fff !important;
        }
        &.active {
          border-color: $success #dee2e6 #fff;
        }
      }
    }
    .nav-item {
      .nav-link.warning-ctm {
        svg {
          color: $warning;
        }
        .badge {
          background: $warning;
          color: #fff !important;
        }
        &.active {
          border-color: $warning #dee2e6 #fff;
        }
      }
    }
    .nav-item {
      .nav-link.danger-ctm {
        svg {
          color: $danger;
        }
        .badge {
          background: $danger;
          color: #fff !important;
        }
        &.active {
          border-color: $danger #dee2e6 #fff;
        }
      }
    }
  }
}

.dashboard {
  .custom-table {
    text-align: center !important;
    .dowbload-con {
      margin: 0px auto !important;
    }
  }
}

.ndr {
  .nav-tabs {
    .nav-item {
      .nav-link {
        &.success-ctm.active {
          border-top: 3px solid;
          border-color: $success #dee2e6 #fff;
          color: $success;
        }
        &.warning-ctm.active {
          border-top: 3px solid;
          border-color: $warning #dee2e6 #fff;
          color: $warning;
        }
      }
    }
  }
}

.livendr {
  .ndrPie-main {
    display: none;
  }
  @include respond-to("medium") {
    .ndrPie-main {
      display: block;
      position: absolute;
      right: 105%;
      top: 6px;
    }
  }
}

.allndr {
  .ndrPie-main {
    display: none;
  }
  @include respond-to("medium") {
    .ndrPie-main {
      display: block;
      position: absolute;
      right: 105%;
      top: 6px;
    }
  }
}

.overview-card {
  .card-overview-list {
    .card-val {
      font-size: 1.8rem;
    }
    .title-card {
      font-size: 11px;
    }
    [class*="col-"] {
      min-width: 120px;
      margin-bottom: 0.5rem;
      &:last-child {
        border-right: unset !important;
      }
    }
    @include respond-to-max("small") {
      [class*="col-"] {
        &:nth-child(2n + 0) {
          border-right: unset !important;
        }
        // &:nth-child(-n + 2) {
        //   margin-bottom: 1.5rem;
        // }
      }
    }
    // @include respond-to("small") {
    //   [class*="col-"] {
    //     &:nth-child(-n + 3) {
    //       margin-bottom: 1.5rem;
    //     }
    //   }
    // }
    // @include respond-to("medium") {
    //   [class*="col-"] {
    //     &:nth-child(-n + 4) {
    //       margin-bottom: 1.5rem;
    //     }
    //   }
    // }
    @include respond-to("large") {
      [class*="col-"] {
        width: calc(100% / 5);
        // &:nth-child(-n + 5) {
        //   margin-bottom: 1.5rem;
        // }
      }
    }
    @include respond-to("extra-large") {
      [class*="col-"] {
        width: calc(100% / 6);
        // &:nth-child(-n + 6) {
        //   margin-bottom: 0.5rem;
        // }
      }      
    }
    .act-pending-area{
      position: absolute;
    }
    .process-loader{
      width:20px;
      height: 20px;
      position: absolute;
      left: calc(-20px - 0.5rem);
      top: -4px;
    }
    .processing-triangle-icon{
      display: inline-block;
      animation: blink 1s linear infinite;
      a{
        transform: translatey(-3px);
        display: inline-block;
        padding-left: 10px;
        color:#F75C1E;
        svg{
          width:20px;
          height:20px;
        }
      }
    }
    @keyframes blink {
      0%,100%{
        transform: scale(1.1);
      }
      50%{
        transform: scale(1);
      }
    }
  }
  &.total-status{
    @include respond-to("large") {
      [class*="col-"] {
        width: 100%;
        border-right:none !important;
      }
    }
    @include respond-to("small") {
      [class*="col-"] {
        &:nth-child(2n + 0) {
          border-right: none !important;
        }
      }
    }
    @include respond-to-max("small") {
      [class*="col-"] {
        border-right: none !important;
      }
    }
  }
}



.border-sort-top:before {
  content: "";
  width: 80px;
  height: 1px;
  background: #dee2e6;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.border-sort-top-2:after {
  content: "";
  width: 80px;
  height: 1px;
  background: #dee2e6;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.border-sort-bottom:before {
  content: "";
  width: 80px;
  height: 1px;
  background: #dee2e6;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.border-sort-bottom-2:after {
  content: "";
  width: 80px;
  height: 1px;
  background: #dee2e6;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.border-sort-left:before {
  content: "";
  width: 1px;
  height: 40px;
  background: #dee2e6;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translatey(-50%);
}
.border-sort-left-2:after {
  content: "";
  width: 1px;
  height: 40px;
  background: #dee2e6;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translatey(-50%);
}
.border-sort-right:before {
  content: "";
  width: 1px;
  height: 40px;
  background: #dee2e6;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translatey(-50%);
}
.border-sort-right-2:after {
  content: "";
  width: 1px;
  height: 40px;
  background: #dee2e6;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translatey(-50%);
}

.freightoverview {
  .card-val {
    font-size: 1.4rem;
    text-align: center !important;
  }
  .card-val.card-val2 {
    font-size: 1.6rem;
  }
  .title-card {
    font-size: 10px;
    text-align: center !important;
  }
  .freight-overview-list {
    margin-bottom: 0.5rem;
    [class*="col-"] {
      min-width: 120px;
    }
  }
}

.remittanceOverview {
  .card-val {
    font-size: 1.4rem;
    text-align: center !important;
  }
  .card-val.card-val2 {
    font-size: 1.6rem;
  }
  .title-card {
    font-size: 10px;
    text-align: center !important;
  }
  .title-card.title-card2 {
    font-size: 12px;
  }

  .remittance-overview-list {
    [class*="col-"] {
      min-width: 100px;
      position: relative;
    }

    @include respond-to-max("small") {
      [class*="col-"] {
        width: calc(100% / 2);
        min-width: 120px;
      }
    }
  }
}

.invoiceDetail {
  .invoice-table-con {
    width: 100%;
    overflow-x: auto;
  }
  .invoice-table {
    min-width: 800px;
    font-size: 1rem;
    thead {
      tr {
        th {
          background: unset;
          color: inherit !important;
        }
      }
    }
    tfoot {
      tr{
        border: 1px solid #8d8d8d;
      }
      & :is(td,th) {
        border: 1px solid #8d8d8d;
        vertical-align: inherit;
      }
    }
    tbody {
      tr{
        border: 1px solid #8d8d8d;
      }
      & :is(td,th) {
        border: 1px solid #8d8d8d;
        vertical-align: inherit;
      }
    }

    .ivc_ttl {
      font-size: 18px;
      font-weight: 500;
    }
    .ivc-text {
      font-size: 18px;
  }
    .ivc_text {
      font-size: 16px;
    }
  }
}

.creditNoteDetail {
  .creditNoteDtl-table-con {
    width: 100%;
    overflow-x: auto;
  }
  .invoice-table {
    min-width: 800px;
    thead {
      tr {
        th {
          background: unset;
          color: inherit !important;          
        }
      }
    }
    tbody {
      tr{
        border: 1px solid #8d8d8d;
      }
      & :is(td,th) {
        border: 1px solid #8d8d8d;
        vertical-align: inherit;
      }
    }
    tfoot {
      tr{
        border: 1px solid #8d8d8d;
      }
      & :is(td,th) {
        border: 1px solid #8d8d8d;
        vertical-align: inherit;
      }
    }

    .ivc_ttl {
      font-size: 16px;
      font-weight: 500;
    }
    .ivc_text {
      font-size: 16px;
    }
  }
}

.debitNoteDetail {
  .debitNoteDtl-table-con {
    width: 100%;
    overflow-x: auto;
  }
  .debit-table {
    min-width: 800px;
    thead {
      tr {
        th {
          background: unset;
          color: inherit !important;          
        }
      }
    }
    tbody {
      tr{
        border: 1px solid #8d8d8d;
      }
      & :is(td,th) {
        border: 1px solid #8d8d8d;
        vertical-align: inherit;
      }
    }
    tfoot {
      tr{
        border: 1px solid #8d8d8d;
      }
      & :is(td,th) {
        border: 1px solid #8d8d8d;
        vertical-align: inherit;
      }
    }

    .ivc_ttl {
      font-size: 16px;
      font-weight: 500;
    }
    .ivc_text {
      font-size: 16px;
    }
  }
}

.weightMismatchModal {
  .formGroupSec {
    .formGroupTitleSec {
      @include respond-to("large") {
        width: 160px !important;
      }
    }
    .formGroupFieldSec {
      @include respond-to("large") {
        width: calc(100% - 160px);
      }
    }
  }
  .download-img-con{
    img{
      display: none;
    }
  }
  .size-title{
    width: 60px;
  }
}

.performaceBarTable {
  &.custom-table {
    text-align: left !important;
    min-width: 90px;
    font-size: 12px;
    height: 238px;
    td {
      padding: 0px;
      border: none !important;
    }
  }
}

.PendingSummary{
  .custom-table{
    thead tr:nth-child(2) th{
      background-color:rgba(27, 90, 143, 0.11);
    }
    font-size: 0.8rem;
    .cw-150{
      width:150px;
      min-width: 150px;
    }
    .cw-45{
      min-width: 45px;
    }
  }
}
.PickupPendingShipments{
  .custom-table{
    thead tr:nth-child(2) th{
      background-color:rgba(27, 90, 143, 0.11);
    }
    font-size: 0.8rem;
    .cw-150{
      width:150px;
      min-width: 150px;
    }
    .cw-45{
      min-width: 45px;
    }
    .inner-custom-table{
      tr:last-child{
        td{
          border-bottom: none;
        }
      }
    }
  }
}
.Performance{
  .custom-table{
    thead tr:nth-child(2) th{
      background-color:rgba(27, 90, 143, 0.11);
    }
    font-size: 0.8rem;
    .cw-150{
      width:150px;
      min-width: 150px;
    }
    .cw-45{
      min-width: 45px;
    }
    .cw-120{
      min-width: 120px;
      width: 120px;
    }
    .inner-custom-table{
      tr:last-child{
        td{
          border-bottom: none;
        }
      }
    }
  }
}


.Stock {
  .card-stock-list {    
    [class*="col-"] {
      // min-width: 120px;
      // margin-bottom: 0.5rem;
      &:last-child {
        border-right: unset !important;
      }
    }
    // @include respond-to-max("small") {
    //   [class*="col-"] {
    //     &:nth-child(2n + 0) {
    //       border-right: unset !important;
    //     }
    //   }
    // }
    // @include respond-to("large") {
    //   [class*="col-"] {
    //     width: calc(100% / 5);
    //   }
    // }
    // @include respond-to("extra-large") {
    //   [class*="col-"] {
    //     width: calc(100% / 6);
    //   }      
    // }
  }
  .media{
    text-align: center;
    .card-val {
      font-size: 1.8rem;
      color:#1b5a8f;
      margin-bottom:0;
    }
    .title-card {
      font-size: 11px;
      @include respond-to("large"){
        margin-bottom:0;
      }
    }
  }
  .max-height-stock{
    .table-responsive{
        max-height:400px;
    }
  }
}

.shipmentCon{
    .some-shipment-detail{
      @include respond-to("extra-large"){
        margin-top:-9px !important;
      }
    }
    .card-shipment-list {
      .card-val {
        font-size: 1.4rem;
        color:#1b5a8f;
        margin-bottom:0;
      }
      .title-card {
        font-size: 11px;
        margin-bottom:0;
      }
      [class*="col-"] {
        min-width: 120px;
        // margin-bottom: 0.5rem;
        &:last-child {
          border-right: unset !important;
        }
      }
      @include respond-to-max("small") {
        [class*="col-"] {
          &:nth-child(2n + 0) {
            border-right: unset !important;
          }
          width: calc(100% / 2);
        }
      }
      // @include respond-to("large") {
      //   [class*="col-"] {
      //     width: calc(100% / 5);
      //   }
      // }
      // @include respond-to("extra-large") {
      //   [class*="col-"] {
      //     width: calc(100% / 6);
      //   }      
      // }
      .card-icon {
        .badge {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          font-size: 1rem;
          @include respond-to-max("small") {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
}

.orderSummary{
  .max-height-odr-smry{
    .table-responsive{
      @include respond-to("large") {
        max-height:265px;
      }
    }
  }
}
.customDot{
  border-radius: 50%;
  margin: 6px;
  height: 10px;
  width: 10px;
  background: #ea5455 !important;
  display: inline-block;
}