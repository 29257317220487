

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.modal-courier {
  width: 100%;
  min-width: auto;
  max-width: 1109px !important;
  margin-bottom: 0 !important;
}
.courier-box .card{
  min-width: 450px !important;
}  
.modal-courier .btn,button{
  font-size: 16px;
}

.dimension{
  letter-spacing: 0.24px;
    color: #A9B0BC;
    font-size: 12px;
    line-height: 19px;
    background: #F3F7FE 0% 0% no-repeat padding-box;
    border-radius: 0px 5px 5px 0px;
    min-height: 34px;
    min-width: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 27px;
    right: 1px;
    border: 1px solid #EFEFEF;
}
.vol-calc .form-group {
  width: 28%;
}
.vol-calc .form-group input{
  padding: 17px !important;
}
.btn-price-cal{
  padding: 7px 15px;
    margin-top: 11px;
}
.weight-barr .dimension {
  top: 27px;
  right: 12.9px;
}
.weight-barr input{
  padding: 17px !important;
}
.weight-barr{
  width: 29.76% !important;
}
.btn-price-cal {
  padding: 7px 13px;
}
.radio-id-tot {
  margin-left: -3px;
  margin-top: -0.5% !important;
}
option.default-opt {
  background: #1b5a8f;
  color: #fff;
}
.sortOrdeer {
  display: inline !important;
    width: 10px !important;
    margin-bottom: 0 !important;
    margin-left: 10px !important;
    padding: 0 17px !important;
    border: none !important;
    /* background: #1b5a8f !important; */
}


.select-box {
  display: none;
}
.courier-table th{
  white-space: nowrap;
}

.down-icon-parent{
  position: relative;
}
.down-icon-pick {
  position: absolute;
  bottom: 10px;
  right: 28px;
}


.wptoken label {
  width: 18%;
}
.wptoken input {
  width: 47.4%;
}

@media (max-width: 992px) {
  .weight-barr {
    width: 100% !important;
    max-width: none;
    min-width: auto;
}
.ship-popup-box .justify-content-end{
  flex-wrap: wrap;
width: 100%;
}
.ship-popup-box .down-icon-parent {
  width: 50% !important;
  max-width: none;
}
.ship-popup-box .vol-calc{
  flex-wrap: wrap !important;
}
.ship-popup-box .form-group{
  width: 100%;
}
.ship-popup-box .chargable {
  margin-top: 30px;
}
.ship-popup-box .down-icon-pick {
  position: absolute;
  bottom: 4px;
  right: 28px;
}
.ship-popup-box .down-icon-parent{
  padding-left: 0 !important;
}
}

.channel-order-tt {
  max-width: 100%;
  overflow-x: auto;
}
.inputs-wrappNew .custom-checkbox {
  margin-right: 50px !important;
  width: 15%;
  margin-bottom: 6px !important;
}
.inputs-wrappNew .custom-control-label{
margin-bottom: 0 !important;
}

.inputs-all-permission .row {
  width: 50%;
}

.permission-checkbox {
  /* max-width: ; */
  /* margin: 0 auto !important; */
  margin-top: 23px !important;
}

@media screen and (max-width: 600px) {
  .verify-newChange{
    width: 100% !important;
  }
  .marginscreen{
    margin-bottom: 15px !important;
  }

}
.marginscreen .btn{
  background-color: #1b5a8f !important;
}

.overlay-n {
  position: fixed;
  top: 0;
  right: 0;
  width: 92%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
  height: 100vh;
}
.overlay-content img{
  width: 100%;
}
.overlay-content h3{
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  color: #1b5a8f;
  font-weight: bold;
  background: #fff;
  padding: 50px;
}

.form-group-custom {
  margin-bottom: 5px; 
}

/* Analytics table Panel scroll*/

.table-scroll-sm {
  overflow: auto; /* Ensures scrolling works */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

.table-scroll-sm::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit browsers like Chrome and Safari */
}


/* Mobile specific style */
@media (max-width: 768px) {
  .social-network-label {
    margin-top: 25px; /* Adjust margin-top for mobile */
  }
}




